































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
