.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form .form-item {
  width: 45%;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.framePage-scroll /deep/ .upload-btn {
  width: 5rem;
  height: 28px;
}
.framePage-scroll /deep/ .upload-btn .el-upload {
  width: 5rem;
  height: 28px !important;
  border: none !important;
}
